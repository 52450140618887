import React, { useEffect, useState } from 'react';
import { bgImgFromData } from '../../lib/helpers';
import { CommercialServiceItemsArray } from '../BestServiceItem/CommercialServiceItemsArray';
import { SpecializedServiceItemsArray } from '../BestServiceItem/SpecializedServiceItemsArray';
import { Link, useLocation } from 'react-router-dom';
import { MaintainenceServiceItemsArray } from '../BestServiceItem/MaintainenceServiceItemsArray';
import cn from 'classnames';

const ServicesDetailsAside = () => {
  const { pathname } = useLocation();

  const category = pathname.split('/')[2];

  const [isCommercialOpen, setIsCommercialOpen] = useState(
    category === 'commercial'
  );
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(
    category === 'specialized'
  );
  const [isMaintainenceOpen, setIsMaintainenceOpen] = useState(
    category === 'maintainence'
  );

  useEffect(() => {
    bgImgFromData();
  }, []);

  return (
    <aside className="services-sidebar">
      <div className="widget">
        <div className="services-cat-list" style={{ margin: '0' }}>
          <h4
            className="title"
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => setIsCommercialOpen(!isCommercialOpen)}
          >
            Commercial Services
            <span
              className="caret"
              style={{
                fontSize: '16px',
              }}
            >
              {isCommercialOpen ? '▲' : '▼'}
            </span>
          </h4>

          {isCommercialOpen && (
            <ul className="list-wrap">
              {CommercialServiceItemsArray.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={item.url}
                      className={pathname === item.url ? 'active' : ''}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      <div className="widget">
        <div className="services-cat-list">
          <h4
            className="title"
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => setIsSpecializedOpen(!isSpecializedOpen)}
          >
            Specialized Services
            <span
              className="caret"
              style={{
                fontSize: '16px',
              }}
            >
              {isSpecializedOpen ? '▲' : '▼'}
            </span>
          </h4>

          {isSpecializedOpen && (
            <ul className="list-wrap">
              {SpecializedServiceItemsArray.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={pathname === item.url ? 'active' : ''}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="widget">
        <div className="services-cat-list">
          <h4
            className="title"
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={() => setIsMaintainenceOpen(!isMaintainenceOpen)}
          >
            Maintainence Services
            <span
              className="caret"
              style={{
                fontSize: '16px',
                position: 'relative',
              }}
            >
              {isMaintainenceOpen ? '▲' : '▼'}
            </span>
          </h4>

          {isMaintainenceOpen && (
            <ul className="list-wrap">
              {MaintainenceServiceItemsArray.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={pathname === item.url ? 'active' : ''}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </aside>
  );
};

export default ServicesDetailsAside;
