import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/garbage-removals-inside.jpg',
  title: 'Efficient Garbage Removal Services for a Clean and Safe Environment',
  introduction:
    'Proper garbage removal is essential for maintaining a clean, hygienic, and environmentally responsible space. At Cheema Janitorial, we provide reliable garbage removal services across Alberta, ensuring that waste is disposed of safely and efficiently. Whether you need regular waste collection or a one-time bulk removal, our team is equipped to handle all your disposal needs.',
  quote: '"A clean space starts with proper waste management and disposal."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of waste do you remove?',
      details: `We handle general waste, bulk trash, construction debris, office waste, and more. However, we do not remove hazardous materials such as chemicals, biohazards, or asbestos.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer scheduled waste removal services?',
      details: `Yes! We provide scheduled waste removal for businesses, residential buildings, and commercial spaces to ensure a consistently clean environment.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you handle large or bulk waste removals?',
      details: `Absolutely! We specialize in bulk waste removal for renovations, office cleanouts, and large-scale cleanups. Just let us know the volume of waste, and we’ll take care of the rest.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you recycle the waste you collect?',
      details: `Whenever possible, we prioritize eco-friendly disposal methods and recycling to minimize environmental impact. We work with local recycling centers to ensure responsible waste management.`,
    },
  ],
};

const GarbageRemovalsPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Garbage Removals'}
          subtitle={'Garbage Removals'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default GarbageRemovalsPage;
