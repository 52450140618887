import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/equipment-repairs-inside.jpg',
  title: 'Reliable Equipment Repair Services for a Seamless Cleaning Operation',
  introduction:
    'When your cleaning equipment breaks down, it can disrupt your workflow and impact the quality of service you provide. At Cheema Janitorial, we offer expert equipment repair services across Alberta, ensuring your cleaning machines stay in top condition. From vacuum cleaners and floor scrubbers to carpet extractors and pressure washers, our skilled technicians can diagnose and fix issues efficiently, minimizing downtime and maximizing productivity.',
  quote:
    '"Well-maintained equipment is the key to efficient and effective cleaning."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of cleaning equipment do you repair?',
      details: `We repair a wide range of cleaning equipment, including vacuum cleaners, floor scrubbers, carpet extractors, pressure washers, and more. If you have a specific machine in need of service, feel free to contact us for more details.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer on-site repair services?',
      details: `Yes! We provide both on-site and in-shop repair services, depending on the complexity of the issue. Our goal is to get your equipment back up and running as quickly as possible.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How long does a typical repair take?',
      details: `Repair times vary based on the issue and availability of parts. Minor repairs may be completed on the same day, while more complex fixes may take a few days. We always strive to provide a fast turnaround to minimize downtime.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer maintenance plans to prevent breakdowns?',
      details: `Yes! We offer preventative maintenance plans to keep your cleaning equipment in peak condition. Regular servicing can extend the lifespan of your machines and help you avoid unexpected breakdowns.`,
    },
  ],
};

const EquipmentRepairsPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Equipment Repairs'}
          subtitle={'Equipment Repairs'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default EquipmentRepairsPage;
