import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';
import { CommercialServiceItemsArray } from '../BestServiceItem/CommercialServiceItemsArray';
import { SpecializedServiceItemsArray } from '../BestServiceItem/SpecializedServiceItemsArray';

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="footer-top">
          <div className="container">
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img src="/img/logo/w_logo.svg" alt="" />
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-8">
                  <div className="footer-social-menu">
                    <ul className="list-wrap">
                      <li>
                        <a href="#">Facebook</a>
                      </li>
                      <li>
                        <a href="#">Twitter</a>
                      </li>
                      <li>
                        <a href="#">LinkedIn</a>
                      </li>
                      <li>
                        <a href="#">Instagram</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <div className="footer-content">
                    <p>
                      12803 - 54 ST NW <br />
                      Edmonton, AB T5A 0C1
                    </p>
                    <div className="footer-contact">
                      <ul className="list-wrap">
                        <li className="phone-addess">
                          <i className="fas fa-phone-alt"></i>
                          <a href="tel:7809094153">780-990-4153</a>
                        </li>
                        <li className="email-addess">
                          <a href="mailto:Envato@gmail.com">
                            cheemajanitorial@yahoo.ca
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Links</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/services">Our Services</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Commercial Services</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      {CommercialServiceItemsArray.map((item) => {
                        return (
                          <li>
                            <Link to={item.url}>{item.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Specialized Services</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      {SpecializedServiceItemsArray.map((item) => {
                        return (
                          <li>
                            <Link to={item.url}>{item.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer bottom */}
        <FooterBottom />
      </div>
    </footer>
  );
};

export default FooterOne;
