export const GalleryList = [
  {
    src: '/img/gallery/1.jpg',
    title: 'Cheema Janitorial',
    caption: 'Test',
  },
  {
    src: '/img/gallery/2.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/4.jpg',
    title: 'Cheema Janitorial',
    tags: [{ value: 'Restaurant Cleaning' }],
  },

  {
    src: '/img/gallery/6.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/7.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/8.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/9.jpg',
    title: 'Cheema Janitorial',
  },

  {
    src: '/img/gallery/11.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/12.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/13.jpg',
    title: 'Cheema Janitorial',
    tags: [{ value: 'Power Washing' }],
  },
  {
    src: '/img/gallery/5.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/10.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/14.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/15.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/16.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/17.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/18.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/19.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/20.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/22.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/23.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/24.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/25.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/26.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/27.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/28.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/29.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/30.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/31.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/32.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/33.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/34.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/35.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/36.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/37.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/38.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/39.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/40.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/41.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/42.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/43.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/44.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/45.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/46.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/47.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/48.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/49.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/50.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/51.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/52.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/53.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/54.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/55.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/56.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/57.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/58.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/59.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/60.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/61.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/62.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/63.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/64.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/65.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/70.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/71.jpg',
    title: 'Cheema Janitorial',
  },
  {
    src: '/img/gallery/72.jpg',
    title: 'Cheema Janitorial',
  },
];
