import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/flooring-inside.jpg',
  title: 'Expert Flooring Services in Alberta',
  introduction:
    'The right flooring enhances the look, durability, and functionality of any space. Whether you need installation, maintenance, or repairs, Cheema Janitorial provides professional flooring services across Alberta. We specialize in a variety of flooring solutions to ensure your space remains clean, safe, and visually appealing.',
  quote:
    '"Well-maintained flooring not only improves aesthetics but also enhances safety and longevity."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of flooring services do you offer?',
      details: `We provide flooring installation, deep cleaning, polishing, waxing, buffing, and repairs. Our services cover various flooring materials, including hardwood, tile, vinyl, laminate, and carpeted surfaces.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer commercial and residential flooring services?',
      details: `Yes! We serve both commercial and residential clients. Whether you need new flooring for an office, retail store, warehouse, or home, our team ensures high-quality workmanship tailored to your needs.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title:
        'How often should floors be professionally cleaned and maintained?',
      details: `The frequency of floor maintenance depends on foot traffic and material type. High-traffic areas should be cleaned and polished regularly, while deep cleaning and waxing are recommended every few months to preserve longevity.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you restore damaged flooring?',
      details: `Absolutely! We offer flooring repairs for scratches, cracks, stains, and wear-and-tear. Our team restores flooring surfaces to their original condition, enhancing both durability and appearance.`,
    },
  ],
};

const FlooringPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'Flooring'} subtitle={'Flooring'} />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default FlooringPage;
