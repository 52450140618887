import React, { useEffect } from 'react';
import AboutUs from '../components/AboutUs/AboutUs';
import BrandArea from '../components/BrandArea/BrandArea';
import BreadcrumbArea from '../components/BreadcrumbArea/BreadcrumbArea';
import OurHistory from '../components/OurHistory/OurHistory';
import ServicesAreaSix from '../components/ServicesArea/ServicesAreaSix';
import TeamOne from '../components/Team/TeamOne';
import VideoAreaTwo from '../components/VideoArea/VideoAreaTwo';
import Layout from '../layouts/Layout';
import { gsapTitleAnimation } from '../lib/gsap-lib/gsapTitleAnimation';
import ServicesAreaFive from '../components/ServicesArea/ServicesAreaFive';

const AboutUsPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'About Us'} subtitle={'About Us'} />
        <AboutUs />
        <ServicesAreaFive />
        <div style={{ marginTop: '80px' }} />
        <VideoAreaTwo />
        {/* <OurHistory />
        <TeamOne /> */}
      </Layout>
    </>
  );
};

export default AboutUsPage;
