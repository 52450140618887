import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/high-dusting-inside.jpg',
  title: 'Professional High Dusting Services in Alberta',
  introduction:
    'Dust accumulation in high and hard-to-reach areas can affect air quality, create safety hazards, and impact the cleanliness of your facility. At Cheema Janitorial, we provide expert high dusting services across Alberta, ensuring ceilings, vents, beams, and other elevated surfaces remain dust-free and well-maintained.',
  quote:
    '"A clean and dust-free environment promotes better air quality and a healthier workspace."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What areas are covered in high dusting?',
      details: `Our high dusting services cover ceilings, vents, pipes, beams, light fixtures, ceiling fans, and other elevated surfaces. We use specialized equipment to remove dust and debris from hard-to-reach places safely and efficiently.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Why is high dusting important?',
      details: `High dusting helps maintain indoor air quality, prevents dust buildup that can cause allergies, and enhances the overall cleanliness of your space. It is essential for offices, warehouses, restaurants, and industrial facilities.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How often should high dusting be done?',
      details: `The frequency depends on the environment and foot traffic. We recommend high dusting at least once every few months for commercial spaces and more frequently for industrial settings with heavy dust accumulation.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you use safe cleaning methods for high dusting?',
      details: `Yes! We use HEPA-filtered vacuums and microfiber tools to safely capture dust without spreading it into the air. Our trained professionals follow strict safety protocols to ensure thorough and efficient cleaning.`,
    },
  ],
};

const HighDustingPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'High Dusting'} subtitle={'High Dusting'} />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default HighDustingPage;
