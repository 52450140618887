import React from 'react';
import GalleryAreaItem from './GalleryAreaItem';
import { GalleryList } from './GalleryList';

const GalleryArea = () => {
  return (
    <section className="project-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-title-three text-center mb-60">
              <span className="sub-title">All</span>
              <h2 className="title">Gallery</h2>
              {/* <p>Currently, we are providing services in these locations.</p> */}
            </div>
          </div>
        </div>

        <div className="row">
          {GalleryList.map((x, index) => (
            <div key={index} className="col-lg-4 col-md-6">
              <GalleryAreaItem item={x} id={index} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GalleryArea;
