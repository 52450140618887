import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/warehouse-inside.jpg',
  title: 'Expert Warehouse Cleaning Services in Alberta',
  introduction:
    'A clean warehouse is essential for maintaining workplace safety, improving efficiency, and meeting industry regulations. At Cheema Janitorial, we provide professional warehouse cleaning services across Alberta, ensuring your facility remains spotless, organized, and compliant with health and safety standards. Our team is equipped to handle warehouses of all sizes with precision and care.',
  quote:
    '"A clean warehouse isn’t just about organization—it’s about safety, productivity, and compliance."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What areas of a warehouse do you clean?',
      details: `We clean all areas of your warehouse, including floors, storage racks, loading docks, offices, break rooms, and restrooms. Our services help remove dust, debris, and spills, ensuring a safe and efficient workspace.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title:
        'Do you offer high-reach cleaning for warehouse ceilings and racks?',
      details: `Yes! We provide high-reach cleaning services for ceilings, beams, ventilation systems, and tall storage racks. Our team uses specialized equipment to remove dust, dirt, and cobwebs from hard-to-reach areas.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you clean warehouses while operations are ongoing?',
      details: `Absolutely! We offer flexible scheduling, including after-hours and weekend cleaning, to ensure minimal disruption to your operations. Our team works efficiently to keep your warehouse clean without interfering with daily activities.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your cleaning services compliant with industry regulations?',
      details: `Yes, we follow all workplace safety and sanitation regulations, including WHMIS and OH&S standards. Our cleaning methods ensure compliance with industry guidelines while maintaining a safe and healthy work environment.`,
    },
  ],
};

const WarehouseCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Warehouse Cleaning'}
          subtitle={'Warehouse Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default WarehouseCleaningPage;
