import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/upholstery-inside.jpg',
  title: 'Professional Upholstery Cleaning Services in Alberta',
  introduction:
    'Furniture and upholstery can trap dust, allergens, and stains over time, affecting indoor air quality and the overall appearance of your space. At Cheema Janitorial, we offer expert upholstery cleaning services across Alberta, using advanced techniques to deep clean and refresh your furniture. Whether for commercial or residential spaces, we ensure your upholstery looks and feels like new.',
  quote:
    '"Clean upholstery not only enhances appearance but also promotes a healthier indoor environment."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of upholstery do you clean?',
      details: `We clean all types of upholstered furniture, including sofas, chairs, office seating, car interiors, and more. Our services cover various fabrics such as microfiber, leather, velvet, and synthetic blends.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What cleaning methods do you use?',
      details: `We use steam cleaning, dry cleaning, and deep extraction methods based on the fabric type and level of soiling. Our team carefully selects the best approach to remove dirt, stains, and allergens without damaging the material.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your cleaning products safe for pets and children?',
      details: `Yes! We use eco-friendly, non-toxic cleaning products that are safe for pets, children, and individuals with allergies. Our cleaning solutions effectively remove stains and odors while maintaining a healthy environment.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How often should upholstery be professionally cleaned?',
      details: `For homes, we recommend professional upholstery cleaning every 6-12 months. Businesses with high foot traffic, such as offices and hotels, may require more frequent cleaning to maintain a fresh and professional appearance.`,
    },
  ],
};

const UpholsteryCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Upholstery Cleaning'}
          subtitle={'Upholsetry Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default UpholsteryCleaningPage;
