import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/power-washing-inside.jpg',
  title: 'Professional Power Washing Services in Alberta',
  introduction:
    'Over time, dirt, grime, mold, and stains can build up on exterior surfaces, making them look worn and uninviting. At Cheema Janitorial, we provide expert power washing services across Alberta, helping businesses and homeowners restore the clean and polished look of their properties. Our high-pressure cleaning solutions effectively remove buildup, leaving surfaces looking like new.',
  quote:
    '"Power washing is the easiest way to restore the beauty of your property’s exterior."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What surfaces can be power washed?',
      details: `We power wash sidewalks, driveways, parking lots, building exteriors, decks, patios, fences, and more. Our high-pressure cleaning removes dirt, algae, oil stains, and graffiti from a variety of surfaces, including concrete, brick, wood, and metal.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Is power washing safe for all surfaces?',
      details: `Yes! Our team adjusts the pressure levels based on the surface type to prevent damage. We use soft washing techniques for delicate areas like wood, siding, and painted surfaces to ensure a thorough but safe clean.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How often should power washing be done?',
      details: `The frequency depends on the environment and usage. We recommend power washing high-traffic areas, such as parking lots and sidewalks, every few months, while building exteriors and decks may need cleaning once or twice a year.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you use eco-friendly cleaning solutions?',
      details: `Yes! We use environmentally safe detergents and cleaning solutions that effectively remove stains without harming plants, pets, or the surrounding environment.`,
    },
  ],
};

const PowerwashingPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'Power Washing'} subtitle={'Power Washing'} />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default PowerwashingPage;
