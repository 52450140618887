import React, { useState } from 'react';
import { BestServiceItemsArray } from '../BestServiceItem/BestServiceItemsArray';
import ServicesAreaFiveItem from './ServicesAreaFiveItem';
import { CommercialServiceItemsArray } from '../BestServiceItem/CommercialServiceItemsArray';
import { SpecializedServiceItemsArray } from '../BestServiceItem/SpecializedServiceItemsArray';
import { MaintainenceServiceItemsArray } from '../BestServiceItem/MaintainenceServiceItemsArray';

const ServicesAreaFive = () => {
  const [tab, setTab] = useState('commercial-services');

  const items =
    tab === 'commercial-services'
      ? CommercialServiceItemsArray
      : tab === 'specialized-services'
      ? SpecializedServiceItemsArray
      : tab === 'maintainence-services'
      ? MaintainenceServiceItemsArray
      : '';

  return (
    <section className="services-area-five">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-title-three text-center mb-60">
              <div>
                <span
                  className={`sub-title-button${
                    tab === 'commercial-services' ? '-active' : ''
                  }`}
                  onClick={() => setTab('commercial-services')}
                >
                  Commercial
                </span>
                <span
                  className={`sub-title-button${
                    tab === 'specialized-services' ? '-active' : ''
                  }`}
                  onClick={() => setTab('specialized-services')}
                  style={{ margin: '0 20px' }}
                >
                  Specialized
                </span>
                <span
                  className={`sub-title-button${
                    tab === 'maintainence-services' ? '-active' : ''
                  }`}
                  onClick={() => setTab('maintainence-services')}
                >
                  Maintainence
                </span>
              </div>
              {tab === 'commercial-services' && (
                <h2 className="title">Commercial Services</h2>
              )}
              {tab === 'specialized-services' && (
                <h2 className="title">Specialized Services</h2>
              )}
              {tab === 'maintainence-services' && (
                <h2 className="title">Maintainence Services</h2>
              )}
              <p>
                {tab === 'commercial-services' &&
                  'We offer comprehensive cleaning solutions designed for busy business environments, including:'}
                {tab === 'specialized-services' &&
                  'In addition to our standard commercial cleaning, we provide a wide range of specialized services to address unique needs:'}
                {tab === 'maintainence-services' &&
                  'Our maintenance services help protect your investment and ensure your facility stays in top condition year-round, including:'}
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {items.map((x, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-10">
              <ServicesAreaFiveItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesAreaFive;
