import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-7">
            <div className="copyright-text">
              <p>
                &copy; 2023, All Rights Reserved by Cheema Janitorial Services
                Ltd
              </p>
              <div
                style={{
                  color: '#FFF',
                  textAlign: window.innerWidth > 966 ? 'left' : 'center',
                }}
                className="footer-bottom-menu "
              >
                Powered by&nbsp;
                <Link to="https://itbs.ca/" target="_blank">
                  ITBS
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-5">
            <div className="footer-bottom-menu">
              <ul className="list-wrap">
                <li>
                  <Link to="/contact">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/contact">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
