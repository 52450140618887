import React from 'react';
import SlickSlider from '../SlickSlider/SlickSlider';
import cn from 'classnames';

const brands = [
  '/img/brand/sobeys.svg',
  '/img/brand/freshco.svg',
  '/img/brand/safeway.svg',
  '/img/brand/safeway.svg',
];

const BrandArea = ({ className }) => {
  return (
    <div className={cn(className ? className : 'brand-area pb-130')}>
      <div className="container">
        <div
          className="clients"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '40px',
            justifyContent: 'space-between',
            flexWrap: window.innerWidth > 992 ? 'nowrap' : 'wrap',
          }}
        >
          <img
            src={`/img/brand/brand_img_new_2.svg`}
            alt=""
            style={{ height: '60px', width: '100%' }}
          />
          <img
            src={`/img/brand/brand_img_new_3.svg`}
            alt=""
            style={{ height: '60px', width: '100%' }}
          />
          <img
            src={`/img/brand/brand_img_new_4.svg`}
            alt=""
            style={{ height: '60px', width: '100%' }}
          />
          <img
            src={`/img/brand/brand_img_new_1.svg`}
            alt=""
            style={{ height: '60px', width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default BrandArea;
