import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/office-inside.jpg',
  title: 'Professional Office Cleaning Services in Alberta',
  introduction:
    'A clean and organized office is essential for productivity, employee well-being, and creating a great first impression for clients. At Cheema Janitorial, we provide top-quality office cleaning services across Alberta, ensuring workspaces remain spotless, sanitized, and welcoming. Our experienced team delivers customized cleaning solutions tailored to your business needs.',
  quote:
    '"A clean office is not just about appearance—it’s about creating a healthier, more productive workspace."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of office spaces do you clean?',
      details: `We clean a variety of office spaces, including corporate offices, coworking spaces, small businesses, and commercial buildings. Our team ensures desks, common areas, meeting rooms, and restrooms are thoroughly sanitized and well-maintained.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer daily office cleaning services?',
      details: `Yes! We offer flexible cleaning schedules, including daily, weekly, and bi-weekly cleaning. Our services include dusting, vacuuming, sanitizing high-touch areas, emptying trash bins, and more to keep your office fresh and hygienic.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your cleaning products safe for employees?',
      details: `Absolutely. We use eco-friendly, non-toxic cleaning products that are safe for employees and visitors. Our green cleaning approach helps maintain indoor air quality while providing effective sanitation.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you clean after business hours?',
      details: `Yes, we provide after-hours and weekend cleaning services to minimize disruptions to your business. Our team works around your schedule to ensure a clean workspace without interrupting daily operations.`,
    },
  ],
};

const OfficeCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Office Cleaning'}
          subtitle={'Office Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default OfficeCleaningPage;
