import { jarallax } from 'jarallax';
import React, { useEffect } from 'react';
import { bgImgFromData } from '../../lib/helpers';
import { Link } from 'react-router-dom';

const BannerThree = () => {
  useEffect(() => {
    bgImgFromData();
  }, []);

  // jarallax
  useEffect(() => {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
    });
  }, []);

  return (
    <section
      className="banner-area-three jarallax banner-bg-three"
      data-background="/img/new-images/banner-new.jpg"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="banner-form-wrap">
              <h2 className="title">Get a Free Estimate</h2>

              <form action="#">
                <div className="form-grp">
                  <input type="text" placeholder="Your name" />
                </div>
                <div className="form-grp">
                  <input type="email" placeholder="Email address" />
                </div>
                <div className="form-grp">
                  <input type="text" placeholder="phone number" />
                </div>
                <div className="form-grp">
                  <select
                    id="shortBy"
                    name="select"
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="">Select Service</option>
                    <option>Office Service</option>
                    <option>Home Service</option>
                    <option>Others Service</option>
                  </select>
                </div>
                <button type="submit" className="btn btn-two">
                  Submit Your Request
                </button>
              </form>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="banner-content-three">
              <h1
                className="title"
                data-aos="fade-right"
                data-aos-delay="0"
                style={{ color: '#333', fontWeight: '50', fontSize: '50px' }}
              >
                Professional
              </h1>

              <h1 style={{ fontSize: '60px', marginTop: '-20px' }}>
                Cleaning Services <br />
                for Businesses
              </h1>

              <div>
                {/* <p
                  data-aos="fade-right"
                  data-aos-delay="300"
                  style={{
                    color: '#333',
                    fontSize: '22px',
                    fontWeight: 'bold',
                  }}
                >
                  Why Choose Us?
                </p> */}
                <div
                  className="introduction-list"
                  style={{ borderColor: '#1239ac' }}
                >
                  <ul className="list-wrap">
                    <li>
                      <i className="fas fa-check-circle"></i>Experienced &amp;
                      Trusted Cleaners
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>Eco-Friendly
                      Cleaning Products
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>100% Satisfaction
                      Guaranteed
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>Affordable &
                      Transparent Pricing
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="banner-btn"
                data-aos="fade-right"
                data-aos-delay="600"
              >
                <Link to="/services" className="btn">
                  Discover MORE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerThree;
