import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { intersectingAnimation } from '../../lib/helpers';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Iframe from 'react-iframe';

const VideoAreaTwo = () => {
  useEffect(() => {
    intersectingAnimation();
  }, []);

  return (
    <section className="video-area inner-video-area has-animation pb-130">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-8 order-0 order-lg-2">
            <div className="video-img-wrap">
              <img src="/img/images/video-img.png" alt="" />
              <img src="/img/images/video-img-front.png" alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="video-content">
              <div className="section-title mb-50">
                <h2 className="title">
                  Why We{' '}
                  <span>
                    Stand Out
                    <svg
                      viewBox="0 0 173 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 22.9998C8.5 14.2152 90 -14 172 14.2148"
                        stroke-width="3"
                      />
                    </svg>
                  </span>
                </h2>
              </div>

              <p>
                Trusted since 2002, our expert janitorial team delivers
                professional cleaning services with exceptional quality, earning
                long-term client satisfaction.
              </p>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginBottom: '20px',
                }}
              >
                <h6>
                  <i
                    className="fas fa-check-circle"
                    style={{ marginRight: '10px' }}
                  ></i>
                  Professional &amp; Trained Team
                </h6>
                <h6>
                  <i
                    className="fas fa-check-circle"
                    style={{ marginRight: '10px' }}
                  ></i>
                  Trusted by Leading Brands
                </h6>
                <h6>
                  <i
                    className="fas fa-check-circle"
                    style={{ marginRight: '10px' }}
                  ></i>
                  100% Satisfaction Guaranteed
                </h6>
                <h6>
                  <i
                    className="fas fa-check-circle"
                    style={{ marginRight: '10px' }}
                  ></i>
                  Affordable & Transparent Pricing
                </h6>
              </div>
              <Link to="/services" className="btn">
                Discover MORE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoAreaTwo;
