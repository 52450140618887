import React, { useEffect } from 'react';
import BreadcrumbArea from '../../components/BreadcrumbArea/BreadcrumbArea';
import Layout from '../../layouts/Layout';
import { gsapTitleAnimation } from '../../lib/gsap-lib/gsapTitleAnimation';
import GalleryArea from '../../components/GalleryArea/GalleryArea';

const GalleryPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'Gallery'} subtitle={'Gallery'} />
        <GalleryArea />
      </Layout>
    </>
  );
};

//
export default GalleryPage;
