import React from 'react';
import { Link } from 'react-router-dom';

const GalleryAreaItem = ({ item, id }) => {
  return (
    <div className="project-item-three">
      <div className="project-thumb-three">
        <Link>
          <img src={item.src} alt="" />
        </Link>
      </div>

      <div className="project-content-three">
        <h2 className="title">
          <Link>{item.title}</Link>
        </h2>
        <span>{item.title}</span>
      </div>
    </div>
  );
};

export default GalleryAreaItem;
