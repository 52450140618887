import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/painting-inside.jpg',
  title: 'Professional Painting Services in Alberta',
  introduction:
    'A fresh coat of paint can transform any space, enhancing its appearance and protecting surfaces from wear and tear. At Cheema Janitorial, we offer expert painting services across Alberta, providing high-quality interior and exterior painting for commercial, industrial, and residential properties. Our skilled team ensures a flawless finish tailored to your needs.',
  quote:
    '"A well-painted space creates a lasting impression and adds value to your property."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of painting services do you offer?',
      details: `We provide interior and exterior painting for offices, retail stores, warehouses, homes, and industrial buildings. Our services include wall painting, trim and molding, ceiling painting, and specialty coatings.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you use eco-friendly and low-VOC paints?',
      details: `Yes! We offer eco-friendly, low-VOC (volatile organic compound) paints that are safer for indoor air quality while providing long-lasting durability and vibrant colors.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you work around our business hours?',
      details: `Absolutely! We provide flexible scheduling, including evenings and weekends, to minimize disruptions to your operations. Our team works efficiently to complete projects on time without compromising quality.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer surface preparation and repairs?',
      details: `Yes! We prepare all surfaces before painting, including patching holes, sanding, priming, and repairing minor damages to ensure a smooth and long-lasting finish.`,
    },
  ],
};

const PaintingPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'Painting'} subtitle={'Painting'} />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default PaintingPage;
