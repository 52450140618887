import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/window-washing-inside.jpg',
  title: 'Professional Window Washing Services in Alberta',
  introduction:
    'Clean windows enhance the appearance of any property, improve natural light, and create a welcoming environment. At Cheema Janitorial, we offer expert window washing services across Alberta, ensuring streak-free, spotless windows for commercial, residential, and industrial properties. Our team uses advanced cleaning techniques to remove dirt, grime, and hard water stains, leaving your windows crystal clear.',
  quote:
    '"Clean windows brighten up your space and make a lasting impression."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of properties do you service?',
      details: `We provide window washing for commercial buildings, offices, retail stores, residential homes, apartments, and high-rise buildings. Our team is equipped to handle jobs of all sizes safely and efficiently.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you clean both interior and exterior windows?',
      details: `Yes! We clean both interior and exterior windows, removing dirt, fingerprints, smudges, and hard water stains to restore clarity and shine.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer high-rise window cleaning?',
      details: `Absolutely! Our trained professionals use specialized equipment, including water-fed poles and harnesses, to clean high-rise and hard-to-reach windows safely and effectively.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How often should windows be professionally cleaned?',
      details: `For commercial properties, we recommend window cleaning every 1-3 months to maintain a professional appearance. Residential windows can be cleaned seasonally or as needed based on weather conditions and location.`,
    },
  ],
};

const WindowWashingPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'Window Washing'} subtitle={'Window Washing'} />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default WindowWashingPage;
