import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/day-porter-inside.jpg',
  title: 'Reliable Day Porter Services in Alberta',
  introduction:
    'Keeping your facility clean and presentable throughout the day is essential for maintaining a professional image and ensuring a safe environment. At Cheema Janitorial, our expert day porter services provide continuous cleaning and maintenance for offices, retail stores, commercial buildings, and more across Alberta. Our skilled porters work behind the scenes to keep your space spotless and running smoothly.',
  quote:
    '"A well-maintained facility enhances productivity, safety, and customer satisfaction."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What does a day porter do?',
      details: `Our day porters handle routine cleaning tasks such as trash removal, restroom upkeep, spill cleanup, lobby maintenance, and high-touch surface disinfection. They ensure your facility remains clean, organized, and welcoming throughout the day.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What types of facilities do you service?',
      details: `We provide day porter services for a variety of facilities, including office buildings, shopping centers, hotels, medical facilities, schools, and industrial sites. Our team is trained to meet the unique cleaning needs of each environment.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer customized day porter schedules?',
      details: `Yes! We offer flexible scheduling to meet your needs, whether you require full-day, part-time, or peak-hour porter services. Our goal is to provide seamless cleaning support that fits your business operations.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How does a day porter differ from a janitor?',
      details: `A day porter focuses on maintaining cleanliness throughout business hours, handling immediate cleaning needs and customer-facing areas. A janitor typically performs deep cleaning after hours. Both services work together to ensure a clean and well-maintained facility.`,
    },
  ],
};

const DayPorterServicesPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Day Porter Services'}
          subtitle={'Day Porter Services'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default DayPorterServicesPage;
