import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/disinfection-inside.jpg',
  title: 'Professional Disinfection & Sanitization Services in Alberta',
  introduction:
    'A clean space isn’t just about appearance—it’s about health and safety. At Cheema Janitorial, we provide expert disinfection and sanitization services across Alberta, helping businesses and facilities maintain a germ-free environment. Our advanced cleaning methods eliminate bacteria, viruses, and other harmful pathogens to create a safer workplace for employees and customers.',
  quote:
    '"A properly disinfected space promotes health, safety, and peace of mind."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What areas do you disinfect?',
      details: `We disinfect all high-touch surfaces, including doorknobs, light switches, countertops, desks, restrooms, and shared equipment. Our services are ideal for offices, schools, medical facilities, retail stores, and industrial sites.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What types of disinfectants do you use?',
      details: `We use hospital-grade, eco-friendly disinfectants that are safe for people, pets, and the environment. Our cleaning solutions are EPA-approved and effective against bacteria, viruses, and fungi, including COVID-19.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How often should a business be disinfected?',
      details: `The frequency of disinfection depends on foot traffic and the nature of the business. High-touch areas should be disinfected daily, while full facility disinfection can be scheduled weekly or as needed to maintain hygiene and safety.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer electrostatic spraying or fogging services?',
      details: `Yes! We provide electrostatic spraying and fogging services for large-scale disinfection. These advanced techniques ensure even coverage and rapid elimination of pathogens on all surfaces, including hard-to-reach areas.`,
    },
  ],
};

const DisinfectionAndSanitizationPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Disinfection & Sanitization'}
          subtitle={'Disinfection & Sanitization'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default DisinfectionAndSanitizationPage;
