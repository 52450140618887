import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';

const validate = (values) => {
  const errors = {};
  if (!values.name.trim()) {
    errors.name = 'Name is required.';
  }
  if (!values.email.trim()) {
    errors.email = 'Email is required.';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email is invalid.';
  }
  if (!values.message.trim()) {
    errors.message = 'Message is required.';
  }
  return errors;
};

const ContactForm = () => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setStatus }
  ) => {
    setStatus({ success: '', error: '' });
    try {
      // Replace the URL with your API endpoint
      const response = await fetch('https://cheemasecurity.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, clientName: 'cheema-janitorial' }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong!');
      }

      setStatus({ success: 'Message sent successfully!', error: '' });
      resetForm();
    } catch (error) {
      setStatus({ success: '', error: error.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <div className="row">
            <div className="col-md-6">
              <div className="form-grp">
                <Field
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-grp">
                <Field
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email address"
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-grp">
                <Field
                  id="phone"
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                />
                <ErrorMessage name="phone" component="div" className="error" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-grp">
                <Field
                  id="subject"
                  type="text"
                  name="subject"
                  placeholder="Subject"
                />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className="error"
                />
              </div>
            </div>
          </div>
          <div className="form-grp">
            <Field
              as="textarea"
              id="message"
              name="message"
              placeholder="Write message"
            />
            <ErrorMessage name="message" component="div" className="error" />
          </div>
          {status && status.success && (
            <div className="success">{status.success}</div>
          )}
          {status && status.error && (
            <div className="error">{status.error}</div>
          )}
          <button type="submit" className="btn" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send a message'}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
