import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/post-construction-inside.jpg',
  title: 'Expert Post-Construction Cleaning Services in Alberta',
  introduction:
    'After construction or renovation, dust, debris, and leftover materials can make a space unsafe and unpresentable. At Cheema Janitorial, we provide thorough post-construction cleaning services across Alberta, ensuring your newly built or renovated property is spotless, safe, and move-in ready. Our team handles everything from fine dust removal to deep cleaning, leaving your space looking its best.',
  quote:
    '"A construction project isn’t complete until the space is clean, safe, and ready for use."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What does post-construction cleaning include?',
      details: `Our post-construction cleaning includes dust and debris removal, floor scrubbing, window and glass cleaning, restroom sanitation, high-touch surface disinfection, and final detailing to ensure a polished, move-in-ready space.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you clean both residential and commercial properties?',
      details: `Yes! We offer post-construction cleaning for residential homes, office buildings, retail stores, warehouses, and industrial spaces. No job is too big or small for our experienced team.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How soon can cleaning be scheduled after construction?',
      details: `We offer flexible scheduling to accommodate your project timeline. Our team is available for same-day, next-day, or scheduled post-construction cleaning to ensure a smooth transition from construction to occupancy.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you remove hazardous materials or construction waste?',
      details: `We remove dust, dirt, and light debris. However, for hazardous materials and large construction waste, we recommend hiring a specialized waste disposal service. We can coordinate with waste removal teams for a complete cleanup solution.`,
    },
  ],
};

const PostConstructionPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Post Construction Cleaning'}
          subtitle={'Post Construction Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default PostConstructionPage;
