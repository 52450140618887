import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/property-maintainence-inside.jpg',
  title: 'Comprehensive Property Maintenance Services for a Well-Kept Space',
  introduction:
    'A well-maintained property not only enhances curb appeal but also ensures safety, functionality, and longevity. At Cheema Janitorial, we offer professional property maintenance services across Alberta, helping businesses, commercial properties, and residential complexes stay in top condition. From routine upkeep to emergency repairs, our team is dedicated to providing reliable and high-quality maintenance solutions tailored to your needs.',
  quote:
    '"A well-maintained property is a reflection of professionalism and care."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of property maintenance services do you offer?',
      details: `We provide a wide range of services, including general repairs, plumbing and electrical maintenance, exterior cleaning, landscaping, and seasonal upkeep. Whether you need regular maintenance or one-time repairs, we’ve got you covered.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer emergency maintenance services?',
      details: `Yes! We understand that unexpected issues can arise at any time. Our team is available for emergency repairs to ensure your property remains safe and functional.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can I schedule regular maintenance for my property?',
      details: `Absolutely! We offer customized maintenance plans to suit your property's specific needs. Regular maintenance helps prevent costly repairs and keeps your space in excellent condition year-round.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you service both residential and commercial properties?',
      details: `Yes, we provide property maintenance services for both residential and commercial properties, including office buildings, apartment complexes, retail spaces, and more.`,
    },
  ],
};

const PropertyMaintainencePage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Property Maintainence'}
          subtitle={'Property Maintainence'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default PropertyMaintainencePage;
