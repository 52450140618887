import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/retail-store-inside.jpg',
  title: 'Pristine Retail Store Cleaning for a Lasting Impression',
  introduction:
    'In the retail industry, a clean and well-maintained store plays a crucial role in attracting customers and boosting sales. Shoppers expect a hygienic and organized environment where they can browse comfortably. At Cheema Janitorial, we specialize in top-tier retail store cleaning services across Alberta, ensuring that your store remains spotless and inviting for customers.',
  quote:
    '"A sparkling clean retail space enhances customer experience and drives business success."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of retail stores do you clean?',
      details:
        'We provide cleaning services for a variety of retail stores, including clothing boutiques, grocery stores, electronics retailers, shopping malls, and more. Our team customizes cleaning plans to fit the unique needs of each business.',
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer after-hours cleaning services?',
      details:
        'Yes! We understand that retail businesses operate during peak hours, so we offer flexible scheduling, including after-hours and overnight cleaning services, to ensure minimal disruption to your operations.',
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What cleaning products do you use?',
      details:
        'We use eco-friendly and non-toxic cleaning products that are safe for customers and employees. Our cleaning solutions effectively remove dirt, germs, and allergens while maintaining a healthy retail environment.',
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How do I get a quote for my store?',
      details:
        'Getting a quote is simple! Contact us via phone or our online contact form, and we will provide a customized cleaning plan and pricing based on the size and specific needs of your retail space.',
    },
  ],
};

const RetailStoreCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Retail Store Cleaning'}
          subtitle={'Retail Store Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default RetailStoreCleaningPage;
