export const FaqList = [
  {
    id: 'headingOne',
    controls: 'collapseOne',
    expanded: true,
    btnClass: '',
    contentClass: 'show',
    title: 'What areas do you service in Alberta?',
    details: `We proudly serve several locations in Alberta. Whether you reside in one of our major urban centers or a smaller community across the province, we're here to help. If you're unsure whether your area is covered, please give us a call or send us a message via our contact form.`,
  },
  {
    id: 'headingTwo',
    controls: 'collapseTwo',
    expanded: false,
    btnClass: 'collapsed',
    contentClass: '',
    title: 'Do you offer eco-friendly cleaning solutions?',
    details: `Yes, we are committed to sustainability. We use environmentally friendly cleaning products that are safe for your family, pets, and the environment while still delivering a thorough clean.`,
  },
  {
    id: 'headingThree',
    controls: 'collapseThree',
    expanded: false,
    btnClass: 'collapsed',
    contentClass: '',
    title: 'What types of cleaning services do you provide?',
    details: `Our services include residential cleaning, commercial and office cleaning, move-in/move-out cleaning, and specialized cleaning services like carpet cleaning, window washing, and post-construction cleaning. If you have unique cleaning needs, just ask!`,
  },
  {
    id: 'headingFour',
    controls: 'collapseFour',
    expanded: false,
    btnClass: 'collapsed',
    contentClass: '',
    title: 'Do you supply your own cleaning equipment and products?',
    details: `Absolutely. Our professional team comes fully equipped with all the necessary supplies and equipment to ensure a high-quality clean every time.`,
  },
  // {
  //   id: "headingFive",
  //   controls: "collapseFive",
  //   expanded: false,
  //   btnClass: "collapsed",
  //   contentClass: "",
  //   title: "How does the 30-day free software trial work?",
  //   details: `LessAccounting is 100% web-based, meaning it can be
  //   accessed from anywhere and there’s no software to
  //   install on your computer. You can easily use
  //   LessAccounting on your iPhone or any Android mobile
  //   device with our cloud accounting. That is what makes it
  //   the best small business accounting software options
  //   among accounting programs.`,
  // },
];
