import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/carts-retrieval-inside.jpg',
  title: 'Reliable Carts Repair Services in Alberta',
  introduction:
    'Functional carts are essential for smooth operations in warehouses, retail stores, hospitality businesses, and industrial settings. Damaged or faulty carts can slow down workflow and pose safety risks. At Cheema Janitorial, we offer expert cart repair services across Alberta, ensuring your equipment remains in top working condition for maximum efficiency and safety.',
  quote:
    '"Well-maintained carts keep your business moving efficiently and safely."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of carts do you repair?',
      details: `We repair a wide range of carts, including utility carts, shopping carts, warehouse trolleys, janitorial carts, and food service carts. Our team handles structural repairs, wheel replacements, and general maintenance to extend their lifespan.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you provide on-site cart repairs?',
      details: `Yes! We offer both on-site and off-site repair services based on your needs. Our technicians can visit your location to assess and repair carts efficiently, minimizing downtime for your business.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How do I know if my cart needs repair or replacement?',
      details: `If your cart has wobbly wheels, broken handles, rust, or structural damage, it may need repairs. Our team can assess the condition and recommend whether a repair or replacement is the best option.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you replace wheels and casters on carts?',
      details: `Absolutely! We provide wheel and caster replacements to restore smooth movement. We use high-quality, durable wheels suited for different environments, from warehouses to retail spaces.`,
    },
  ],
};

const CartsRepairPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea title={'Carts Repair'} subtitle={'Carts Repair'} />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default CartsRepairPage;
