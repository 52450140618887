import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { ProgressBarItems } from '../ProgressBar/ProgressBarItems.js';

const AboutUsThree = () => {
  return (
    <section className="about-area-three pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-10">
            <div className="about-img-wrap-two">
              <img src="/img/images/who-we-are.jpg" alt="" />
              <img
                src="/img/images/who-we-are-back.jpg"
                data-aos="fade-up"
                alt=""
                style={{ objectFit: 'cover', width: '65%', height: '65%' }}
              />
              <img src="/img/images/h4_about_shape01.png" alt="" />
            </div>
          </div>

          <div className="col-xl-5 col-lg-6">
            <div className="about-content-three">
              <div className="section-title-three mb-25">
                <span className="sub-title">Who we are</span>
                <h2 className="title">One Stop Commercial Cleaning Company</h2>
              </div>

              <p>
                Cheema Janitorial Services Ltd has been serving Alberta for 23
                years, providing top-tier cleaning services for Retail Stores
                and businesses. We believe in creating clean, healthy, and
                welcoming spaces for our clients.
              </p>
            </div>
            <div className="about-content-three">
              <div className="section-title-three mb-25">
                <span className="sub-title">Our Mission</span>
              </div>

              <p>
                To deliver high-quality, reliable, and eco-friendly cleaning
                services with a focus on customer satisfaction.
              </p>
            </div>
            <div className="about-content-three">
              <div className="section-title-three mb-25">
                <span className="sub-title">Our Vision</span>
              </div>

              <p>
                To lead in commercial cleaning by providing eco-friendly,
                high-quality services that ensure clean and welcoming spaces for
                every client.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsThree;
