import React from 'react';
import BeforeAfterSlider from '../BeforeAfterSlider/BeforeAfterSlider';
import FaqItem from '../FaqArea/FaqItem';
import { FaqList } from '../FaqArea/FaqList';
import ServicesDetailsAside from './ServicesDetailsAside';
import { useLocation } from 'react-router-dom';

const ServicesDetailsArea = ({
  title,
  introduction,
  quote,
  faqList,
  image,
}) => {
  const { pathname } = useLocation();
  return (
    <section className="services-deatails-area pt-130 pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 order-0 order-lg-2">
            <div className="services-details-wrap">
              <div
                style={{
                  width: '200px',
                  textAlign: 'center',
                  fontWeight: '900',
                  color: '#fff',
                  marginBottom: '16px',
                  padding: '5px 10px',
                  background: '#1239ac',
                  borderRadius: '20px',
                  textTransform: 'capitalize',
                }}
              >
                {pathname.split('/')[2]} Service
              </div>
              <h2 className="title">{title}</h2>
              <p>{introduction}</p>
              <div className="services-details-thumb">
                <img src={image} alt="" />
              </div>
              <div className="services-details-content">
                <p className="different-info">{quote}</p>

                <div className="services-faq faq-content">
                  <div className="accordion" id="accordionExample">
                    {faqList.map((x, index) => (
                      <FaqItem
                        key={index}
                        parentId={'accordionExample'}
                        item={x}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-8">
            <ServicesDetailsAside />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesDetailsArea;
