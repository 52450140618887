import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/industrial-inside.jpg',
  title: 'Reliable Industrial Cleaning Services in Alberta',
  introduction:
    'Industrial facilities require specialized cleaning solutions to maintain safety, efficiency, and compliance with industry regulations. At Cheema Janitorial, we offer professional industrial cleaning services across Alberta, ensuring factories, warehouses, and manufacturing plants remain spotless, safe, and operational. Our expert team uses advanced cleaning techniques and equipment to handle even the toughest jobs.',
  quote:
    '"A clean and well-maintained industrial space is essential for safety, productivity, and compliance."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of industrial facilities do you clean?',
      details: `We clean a variety of industrial spaces, including warehouses, manufacturing plants, factories, distribution centers, and storage facilities. Our team is trained to handle industrial-grade cleaning needs with efficiency and precision.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer high-level cleaning for hard-to-reach areas?',
      details: `Yes! We provide high-level cleaning for ceilings, beams, ventilation systems, and other hard-to-reach areas. Our team uses specialized equipment and safety protocols to ensure thorough cleaning without disrupting operations.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your cleaning services compliant with industry regulations?',
      details: `Absolutely. We follow all industry safety and sanitation standards, including WHMIS and OH&S regulations. Our cleaning methods and products ensure compliance while maintaining a safe working environment.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you provide scheduled maintenance cleaning?',
      details: `Yes, we offer one-time, daily, weekly, and monthly cleaning schedules tailored to your facility’s needs. Regular maintenance cleaning helps prevent buildup, maintain hygiene, and ensure smooth operations.`,
    },
  ],
};

const IndustrialCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Industrial Cleaning'}
          subtitle={'Industrial Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default IndustrialCleaningPage;
