import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

const HeaderNavMenu = () => {
  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? 'active' : '';
  };

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        <li
          className={cn('menu-item-has-children', {
            active: ['/', '/home-2', '/home-3', '/home-4'].includes(pathname),
          })}
        >
          <Link to="/">Home</Link>
        </li>
        <li className={cn(isActiveClassName('/about'))}>
          <Link to="/about">About Us</Link>
        </li>
        <li
          className={cn('menu-item-has-children', {
            active: ['/services'].includes(pathname),
          })}
        >
          <Link to="/services">Services</Link>
        </li>
        <li
          className={cn('menu-item-has-children', {
            active: ['/gallery'].includes(pathname),
          })}
        >
          <Link to="/gallery">Gallery</Link>
        </li>
        <li
          className={cn('menu-item-has-children', {
            active: ['/contact'].includes(pathname),
          })}
        >
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default HeaderNavMenu;
