import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/carpet-cleaning-inside.jpg',
  title: 'Professional Carpet Cleaning Services in Alberta',
  introduction:
    'Carpets can trap dirt, allergens, and bacteria, affecting indoor air quality and overall cleanliness. Regular professional cleaning extends the life of your carpets and creates a healthier environment for your home or business. At Cheema Janitorial, we provide expert carpet cleaning services across Alberta, using advanced techniques to remove deep-seated dirt and stains effectively.',
  quote:
    '"A clean carpet not only looks great but also promotes a healthier indoor environment."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of carpets do you clean?',
      details: `We clean all types of carpets, including residential, commercial, high-traffic office carpets, and specialty rugs. Our cleaning methods are tailored to suit different fabrics and materials to ensure the best results.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What carpet cleaning methods do you use?',
      details: `We use a variety of methods, including hot water extraction (steam cleaning), dry carpet cleaning, and deep shampooing. Our team selects the best approach based on your carpet type and level of soiling.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How long does it take for carpets to dry after cleaning?',
      details: `Drying times depend on the cleaning method used. Steam cleaning typically requires 6-12 hours, while dry cleaning allows for immediate use. We use high-efficiency drying techniques to speed up the process whenever possible.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your carpet cleaning products safe for pets and children?',
      details: `Yes! We use eco-friendly and non-toxic cleaning products that are safe for children, pets, and allergy sufferers. Our cleaning solutions effectively remove dirt and bacteria while ensuring a safe indoor environment.`,
    },
  ],
};

const CarpetCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Carpet Cleaning'}
          subtitle={'Carpet Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default CarpetCleaningPage;
