import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/hospitality-inside.jpg',
  title: 'Exceptional Hospitality Cleaning Services in Alberta',
  introduction:
    'Cleanliness plays a crucial role in the hospitality industry, directly impacting guest experiences, reviews, and overall business success. Whether you operate a hotel, resort, bed and breakfast, or event venue, maintaining pristine spaces is essential. At Cheema Janitorial, we provide top-tier hospitality cleaning services across Alberta, ensuring your establishment is spotless, hygienic, and welcoming for every guest.',
  quote:
    '"A clean and well-maintained hospitality space is key to creating unforgettable guest experiences."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What hospitality spaces do you clean?',
      details: `We clean a wide range of hospitality spaces, including hotels, motels, resorts, bed and breakfasts, conference centers, and event venues. Our team ensures every area, from guest rooms to lobbies and dining spaces, meets the highest cleanliness standards.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you offer deep cleaning services?',
      details: `Yes! Our deep cleaning services include carpet and upholstery cleaning, high-touch surface disinfection, and detailed sanitation of guest rooms, kitchens, and common areas. We use industry-approved cleaning agents to ensure a safe and hygienic environment.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your cleaning products safe for guests and staff?',
      details: `Absolutely. We use eco-friendly and non-toxic cleaning products that are safe for guests, employees, and the environment. Our commitment to green cleaning ensures a healthier space without compromising on cleanliness.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'How often should hospitality spaces be cleaned?',
      details: `Daily cleaning is essential for maintaining a welcoming environment. We recommend routine cleaning for guest rooms and high-traffic areas, with periodic deep cleaning to maintain long-term hygiene and freshness.`,
    },
  ],
};

const HospitalityPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Hospitality Cleaning'}
          subtitle={'Hospitality Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default HospitalityPage;
