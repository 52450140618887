export const TestimonialItemsArray = [
  {
    src: "",
    title: "",
    designation: "",
    desc: `Cheema Janitorial has been fantastic to work with. Their team is always punctual, professional, and delivers exceptional results every time.`,
  },
  {
    src: "",
    title: "",
    designation: "",
    desc: `The quality and reliability of their cleaning services have exceeded our expectations. Each visit leaves our space spotless and fresh.`,
  },
  {
    src: "",
    title: "",
    designation: "",
    desc: `We've been extremely satisfied with their services. The team is punctual, thorough, and maintains our facility to the highest standards.`,
  
  },
  {
    src: "",
    title: "",
    designation: "",
    desc: `Their attention to detail and consistent service quality make them our top choice for commercial cleaning. We highly recommend their services`,
  }
];
