import { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import AnimatedCursor from 'react-animated-cursor';
import HomeOne from './pages/Homes/HomeOne';
import WOW from 'wow.js';
import AOS from 'aos';
import HomeTwo from './pages/Homes/HomeTwo';
import HomeThree from './pages/Homes/HomeThree';
import HomeFour from './pages/Homes/HomeFour';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPageOne from './pages/Services/ServicesPageOne';
import ServicesPageTwo from './pages/Services/ServicesPageTwo';
import ServicesDetailsPage from './pages/Services/ServicesDetailsPage';
import ProjectPageOne from './pages/Projects/ProjectPageOne';
import ProjectPageTwo from './pages/Projects/ProjectPageTwo';
import ProjectDetailsPage from './pages/Projects/ProjectDetailsPage';
import TeamPage from './pages/TeamPages/TeamPage';
import TeamDetailsPage from './pages/TeamPages/TeamDetailsPage';
import EstimatePage from './pages/EstimatePage';
import ShopPage from './pages/ShopPages/ShopPage';
import ShopDetailsPage from './pages/ShopPages/ShopDetailsPage';
import BlogPage from './pages/BlogPages/BlogPage';
import BlogListPage from './pages/BlogPages/BlogListPage';
import BlogDetailsPage from './pages/BlogPages/BlogDetailsPage';
import ContactPage from './pages/ContactPage';
import HospitalityPage from './pages/Services/Commercial/HospitalityCleaning/HospitalityCleaningPage';
import OfficeCleaningPage from './pages/Services/Commercial/OfficeCleaning/OfficeCleaningPage';
import IndustrialCleaning from './pages/Services/Commercial/IndustrialCleaning/IndustrialCleaningPage';
import RetailStoreCleaningPage from './pages/Services/Commercial/RetailStoreCleaning/RetailStoreCleaningPage';
import IndustrialCleaningPage from './pages/Services/Commercial/IndustrialCleaning/IndustrialCleaningPage';
import RestaurantCleaningPage from './pages/Services/Commercial/RestaurantCleaning/RestaurantCleaningPage';
import UpholsteryCleaningPage from './pages/Services/Specialized/UpholsteryCleaning/UpholsteryCleaningPage';
import WindowWashingPage from './pages/Services/Specialized/WindowWashing/WindowWashingPage';
import PostConstructionPage from './pages/Services/Specialized/PostConstruction/PostConstructionPage';
import DisinfectionAndSanitizationPage from './pages/Services/Specialized/DisinfectionAndSanitization/DisinfectionAndSanitizationPage';
import PaintingPage from './pages/Services/Maintainence/Painting/PaintingPage';
import FlooringPage from './pages/Services/Maintainence/Flooring/FlooringPage';
import HighDustingPage from './pages/Services/Specialized/HighDusting/HighDusting';
import PowerwashingPage from './pages/Services/Specialized/PowerWashing/PowerWashingPage';
import WarehouseCleaningPage from './pages/Services/Commercial/WarehouseCleaning/WarehouseCleaningPage';
import CarpetCleaningPage from './pages/Services/Specialized/CarpetCleaning/CarpetCleaningPage';
import CeilingTilesRepairPage from './pages/Services/Specialized/CeilingTilesRepair/CeilingTilesRepairPage';
import CartsRepairPage from './pages/Services/Maintainence/CartsRepair/CartsRepairPage';
import DayPorterServicesPage from './pages/Services/Specialized/DayPorterServices/DayPorterServicesPage';
import EquipmentRepairsPage from './pages/Services/Maintainence/EquipmentRepairs/EquipmentRepairsPage';
import PropertyMaintainencePage from './pages/Services/Maintainence/PropertyMaintainence/PropertyMaintainencePage';
import GarbageRemovalsPage from './pages/Services/Maintainence/GarbageRemovals/GarbageRemovalsPage';
import GalleryPage from './pages/Services/GalleryPage';

import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  // wow js scroll
  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  // aos scroll
  useEffect(() => {
    AOS.init({ duration: 1000, mirror: true, once: true, disable: 'mobile' });
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return (
    <>
      <SpeedInsights />
      <AnimatedCursor
        innerSize={10}
        outerSize={32}
        color="0, 124, 251"
        outerAlpha={0}
        innerScale={0}
        outerScale={0}
        trailingSpeed={1000}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'option',
          'textarea',
          'button',
          '.link',
          '.swiper-pagination-bullet',
        ]}
      />

      <Routes>
        <Route path="/" element={<HomeThree />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPageOne />} />
        <Route path="/gallery" element={<GalleryPage />} />

        <Route path="/contact" element={<ContactPage />} />

        {/* Commercial Services Details */}
        <Route
          path="/services/commercial/hospitality"
          element={<HospitalityPage />}
        />
        <Route
          path="/services/commercial/office-cleaning"
          element={<OfficeCleaningPage />}
        />
        <Route
          path="/services/commercial/retail-store-cleaning"
          element={<RetailStoreCleaningPage />}
        />
        <Route
          path="/services/commercial/industrial-cleaning"
          element={<IndustrialCleaningPage />}
        />
        <Route
          path="/services/commercial/warehouse-cleaning"
          element={<WarehouseCleaningPage />}
        />
        <Route
          path="/services/commercial/restaurant-cleaning"
          element={<RestaurantCleaningPage />}
        />

        {/* Specialized Services Details */}
        <Route
          path="/services/specialized/carpet-cleaning"
          element={<CarpetCleaningPage />}
        />
        <Route
          path="/services/specialized/upholstery-cleaning"
          element={<UpholsteryCleaningPage />}
        />
        <Route
          path="/services/specialized/window-washing"
          element={<WindowWashingPage />}
        />
        <Route
          path="/services/specialized/post-construction-cleaning"
          element={<PostConstructionPage />}
        />
        <Route
          path="/services/specialized/disinfection-and-sanitization"
          element={<DisinfectionAndSanitizationPage />}
        />
        <Route
          path="/services/specialized/ceiling-tiles-repair"
          element={<CeilingTilesRepairPage />}
        />
        <Route
          path="/services/specialized/high-dusting"
          element={<HighDustingPage />}
        />
        <Route
          path="/services/specialized/power-washing"
          element={<PowerwashingPage />}
        />
        <Route
          path="/services/specialized/day-porter-services"
          element={<DayPorterServicesPage />}
        />

        {/* Maintainence Services Details */}
        <Route
          path="/services/maintainence/painting"
          element={<PaintingPage />}
        />
        <Route
          path="/services/maintainence/flooring"
          element={<FlooringPage />}
        />
        <Route
          path="/services/maintainence/equipment-repairs"
          element={<EquipmentRepairsPage />}
        />
        <Route
          path="/services/maintainence/property-maintainence"
          element={<PropertyMaintainencePage />}
        />
        <Route
          path="/services/maintainence/garbage-removals"
          element={<GarbageRemovalsPage />}
        />
        <Route
          path="/services/maintainence/carts-repair"
          element={<CartsRepairPage />}
        />
      </Routes>
    </>
  );
}

export default App;
