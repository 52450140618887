import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/ceiling-tiles-repair-inside.jpg',
  title: 'Expert Ceiling Tile Repair Services in Alberta',
  introduction:
    'Damaged or stained ceiling tiles can negatively impact the appearance and functionality of your space. Whether in offices, retail stores, restaurants, or industrial buildings, maintaining ceiling tiles is essential for aesthetics, insulation, and fire safety. At Cheema Janitorial, we offer professional ceiling tile repair services across Alberta, ensuring your ceilings look clean, well-maintained, and structurally sound.',
  quote:
    '"Well-maintained ceiling tiles enhance the look of your space while improving safety and efficiency."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of ceiling tiles do you repair?',
      details: `We repair various ceiling tile types, including acoustic tiles, drop ceilings, suspended ceilings, and decorative panels. Whether your tiles have water damage, cracks, or discoloration, we restore them to their original condition.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Can you replace individual ceiling tiles?',
      details: `Yes! If some tiles are beyond repair, we can replace individual tiles to match your existing ceiling. We source high-quality materials that blend seamlessly with your current setup.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What causes ceiling tile damage?',
      details: `Common causes of ceiling tile damage include water leaks, humidity, aging, impact damage, and fire hazards. Our team can assess the damage, repair affected areas, and help prevent future issues.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you provide ceiling tile cleaning as well?',
      details: `Absolutely! In addition to repairs, we offer ceiling tile cleaning services to remove stains, dust, and discoloration, keeping your space looking fresh and professional.`,
    },
  ],
};

const CeilingTilesRepairPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Ceiling Tiles Repair'}
          subtitle={'Ceiling Tiles Repair'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default CeilingTilesRepairPage;
