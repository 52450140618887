import React, { useEffect } from 'react';
import BrandArea from '../../../../components/BrandArea/BrandArea';
import BreadcrumbArea from '../../../../components/BreadcrumbArea/BreadcrumbArea';
import ServicesDetailsArea from '../../../../components/ServicesDetailsArea/ServicesDetailsArea';
import Layout from '../../../../layouts/Layout';
import { gsapTitleAnimation } from '../../../../lib/gsap-lib/gsapTitleAnimation';

const content = {
  image: '/img/services/restaurant-inside.jpg',
  title: 'Professional Retail Store Cleaning Services in Alberta',
  introduction:
    'A clean and well-maintained retail store enhances customer experience, promotes a positive brand image, and ensures a safe shopping environment. At Cheema Janitorial, we provide expert retail store cleaning services across Alberta, helping businesses maintain spotless and inviting spaces that attract and retain customers.',
  quote:
    '"A clean store creates a lasting first impression and keeps customers coming back."',
  faqList: [
    {
      id: 'headingOne',
      controls: 'collapseOne',
      expanded: true,
      btnClass: '',
      contentClass: 'show',
      title: 'What types of retail stores do you clean?',
      details: `We clean a variety of retail spaces, including boutiques, department stores, shopping malls, grocery stores, and showrooms. Our cleaning services are tailored to meet the unique needs of each retail environment.`,
    },
    {
      id: 'headingTwo',
      controls: 'collapseTwo',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Do you clean during or after business hours?',
      details: `We offer flexible scheduling, including after-hours and early-morning cleaning services, to minimize disruption to your customers and staff. Our team works around your business hours to maintain a pristine store environment.`,
    },
    {
      id: 'headingThree',
      controls: 'collapseThree',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'What cleaning services do you provide for retail stores?',
      details: `Our retail cleaning services include floor care (sweeping, mopping, and waxing), dusting, glass and window cleaning, restroom sanitation, high-touch surface disinfection, and trash removal. We customize our services to fit your store’s specific needs.`,
    },
    {
      id: 'headingFour',
      controls: 'collapseFour',
      expanded: false,
      btnClass: 'collapsed',
      contentClass: '',
      title: 'Are your cleaning products safe for shoppers and employees?',
      details: `Yes! We use eco-friendly and non-toxic cleaning products that are safe for customers and employees while effectively maintaining a hygienic environment. Our commitment to green cleaning ensures a healthier shopping experience.`,
    },
  ],
};

const RestaurantCleaningPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout header={3} footer={1}>
        <BreadcrumbArea
          title={'Restaurant Cleaning'}
          subtitle={'Restaurant Cleaning'}
        />
        <ServicesDetailsArea
          title={content.title}
          quote={content.quote}
          faqList={content.faqList}
          image={content.image}
          introduction={content.introduction}
        />
        <BrandArea className={'inner-brand-area pb-70'} />
      </Layout>
    </>
  );
};

export default RestaurantCleaningPage;
